import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import formatString from 'format-string-by-pattern';
import { ArrowRightIcon, CameraIcon, InformationCircleIcon } from '@heroicons/react/solid';
import SearchIcon from '../../assets/image/icons/search-icon.svg';
import SortIcon from '../../assets/image/icons/sort-arrows-icon.svg';
import Header from '../../components/Header';
import { callFBFunction, getAllDealerInventoryByRooftop } from '../../utils/firebase';
import withCustomer from '../../redux/Hoc/withCustomer';
import withLoader from '../../redux/Hoc/withLoader';
import Modal from '../../components/Modal';
import { checkPermission } from '../../helpers/app';
import withUser from '../../redux/Hoc/withUser';
import FilterModal from '../../components/FilterModal';
import NoInformation from '../../components/NoInformation';

const ChooseVehicle = ({ user, setLoader, setCustomer, resetCustomer }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [search, setSearch] = useState('');
  const [sortModal, setSortModal] = useState(false);
  const [infoModal, setInfoModal] = useState({
    status: false,
    type: ''
  });

  const [vehicle, setVehicle] = useState([]);
  const [filter, setFilter] = useState('model');
  const [order, setOrder] = useState(true);

  function filterByValue(array, string) {
    return array.filter(
      (data) => JSON.stringify(data).toLowerCase().indexOf(string.toLowerCase()) !== -1
    );
  }

  useEffect(() => {
    checkPermission(user, navigate);
  }, []);

  useEffect(() => {
    setLoader(true);
    getAllDealerInventoryByRooftop(user?.rooftop, filter, order)
      .then((response) => {
        const res = response.filter((item) => {
          if (isEmpty(item.customer)) {
            return true;
          }
          if (item?.customer?.staff === user?.staff) {
            if (item?.onboard && item?.fb_image) {
              return false;
            }
            return true;
          }
          return false;
        });
        setVehicle(filterByValue(res, search));
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  }, [search, filter, order]);

  const CheckUser = (item) => {
    setLoader(true);
    callFBFunction('getCustomerInfo', { vin: item.vin })
      .then(({ data }) => {
        setLoader(false);
        if (!isEmpty(data) && data.status) {
          setCustomer({
            dvReceived: data.data.dv_received,
            onBoard: data.data.onboard,
            ...data.data.customer,
            phone: formatString('(402) 555-1212', data.data.customer.phone)
          });
        }
        navigate('/select-photo', { replace: true });
      })
      .catch(() => setLoader(false));
  };

  useEffect(() => {
    if (pathname === '/inventory') {
      resetCustomer();
    }
  }, [pathname]);

  return (
    <>
      <Header title="Inventory" subtitle="Select Vehicle" showBack={false} />

      <div className="flex flex-row gap-1 p-5">
        <div className="flex-1 flex flex-row gap-2 items-center text-[12px] text-[#CDCDCD] border-b-[1px] border-[#EAEAED] pb-2.5">
          <img className="w-[17px] aspect-square" src={SearchIcon} alt="back" />
          <input
            className="flex-1 bg-transparent border-0 focus:ring-0 text-black placeholder:text-[#EAEAED] p-0"
            name="search"
            placeholder="Search"
            type="text"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <button
          type="button"
          className="flex flex-row items-center gap-2 text-[16px] text-[#5046E4] font-semibold pb-2.5">
          <span onClick={() => setSortModal(true)}>Sort by</span>
          <img
            className="w-2.5 h-auto"
            src={SortIcon}
            alt="back"
            onChange={() => setOrder(false)}
          />
        </button>
      </div>

      {vehicle && vehicle.length > 0 ? (
        <div className="flex flex-col gap-2 overflow-y-scroll mx-5">
          {vehicle.map((item, index) => (
            <div
              className="flex flex-row justify-between gap-2 p-2 border rounded-md transition-all"
              key={index}>
              <div className="flex-1 flex-col gap-2">
                <p className="text-sm font-semibold text-ellipsis overflow-hidden">
                  {item?.year} {item?.make?.toUpperCase()} {item?.model?.toUpperCase()}
                </p>
                <p className="text-[11px] text-gray-500 font-normal">
                  VIN: {item?.vin.toUpperCase()}
                </p>
              </div>
              <div className="flex-initial flex flex-row items-center justify-center gap-2">
                {item?.dv_received &&
                  (isEmpty(item?.customer?.fname) ||
                    isEmpty(item?.customer?.lname) ||
                    (isEmpty(item?.customer?.phone) && isEmpty(item?.customer?.email))) && (
                    <button
                      type="button"
                      onClick={() => {
                        setInfoModal({
                          status: true,
                          type: 'info'
                        });
                      }}
                      className="w-8 h-8 flex items-center justify-center border bg-gray-200 rounded-md">
                      <InformationCircleIcon className="w-5 h-5 text-[#5046E4]" />
                    </button>
                  )}
                {item?.dv_received && isEmpty(item?.customer?.delivery_photo) && (
                  <button
                    type="button"
                    onClick={() => {
                      setInfoModal({
                        status: true,
                        type: 'camera'
                      });
                    }}
                    className="w-8 h-8 flex items-center justify-center border bg-gray-200 rounded-md">
                    <CameraIcon className="w-5 h-5 text-red-500" />
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => {
                    setCustomer({
                      vin: item.vin,
                      make: item.make,
                      model: item.model,
                      year: item.year
                    });
                    CheckUser(item);
                  }}
                  className="bg-[#E4E7EB] text-[#333333] font-medium text-[12px] p-2 transition-all rounded-md">
                  <ArrowRightIcon width={15} />
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoInformation
          title="No Inventory Available"
          content={
            <>
              We apologize, but it seems we're currently out of stock for the vehicle you're
              interested in. Our inventory updates regularly, so please check back soon or explore
              our <span className="text-[#5046E4]">add vehicle</span> feature to add new vehicle in
              the inventory.
            </>
          }
        />
      )}

      <FilterModal
        modal={sortModal}
        onClick={(item) => {
          setFilter(item);
          setSortModal(false);
        }}
        onOutsideClick={() => setSortModal(false)}
      />

      <Modal
        modal={infoModal.status}
        customPadding
        contentClass="!text-sm font-light"
        icon={
          infoModal.type === 'camera' ? (
            <CameraIcon className="w-10 h-10 text-red-500" />
          ) : (
            <InformationCircleIcon className="w-10 h-10 text-[#5046E4]" />
          )
        }
        content={
          infoModal.type === 'camera'
            ? 'Delivery image is missing, please select one so it can be posted to Facebook, if social option was selected.'
            : 'There is some information missing and needs to be filled out, select this vehicle and submit in the next step.'
        }
        onOutsideClick={() => {
          setInfoModal({
            status: false,
            type: ''
          });
        }}
      />
    </>
  );
};

export default withLoader(withUser(withCustomer(ChooseVehicle)));
